@use '../Styles/Partials/fonts' as *;
@use '../Styles/Partials/mixins' as *;
@use '../Styles/Partials/colours' as *;

* {
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    box-sizing: border-box;
    overscroll-behavior-y: none;
}

a {
    text-decoration: none;
    color: inherit;
}