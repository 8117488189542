@use '../../Styles/Partials/fonts' as *;
@use '../../Styles/Partials/mixins' as *;
@use '../../Styles/Partials/colours' as *;

.header {
    background: linear-gradient(black, $Main);
    display: flex;
    justify-content: space-between;
    height: 5rem;
    width: 100%;
    padding: 0 2rem;
    align-items: center;

    @include desktop {
        padding: 0 calc((100vw - 64rem) / 2);
    }

    &__logo {
        align-self: flex-start;
        & > h1 {
            font-family: "Road Rage";
            font-size: 2rem;
            background: linear-gradient(to right, $Secondary, white);
            background-clip: text;
            color: transparent;
            padding: .5rem;
            transition: 0.3s ease-in-out;

            &:hover {
                font-size: 2.15rem;
            }
        }
    }

    &__mobile-nav {
        align-items: center;
        display: flex;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 100%);
        font-size: 1.5rem;
        cursor: pointer;
        color: white;

        @include tablet {
            display: none;
            color: red;
        }

    }

    &__nav {
        display: none;
        flex-grow: 1;   
        background: linear-gradient(to right, white, $Secondary);
        background-clip: text;
        @include tablet {
            display: flex;
            justify-content: right;
        }
    }

    &__link {
        font-size: 1rem;
        line-height: 5rem;
        height: 5rem;
        color: transparent;
        transition: 0.3s ease-in-out;
        margin-right: 3rem;
        
        &:hover {
            font-size: 1.1rem;
            border-bottom: 2px solid $Secondary;
        }
    }

    &__button {
        display: none;
        transition: 0.3s ease-in-out;
        
        @include tablet {
            display: flex;
            align-items: center
        }

        @include button;
        background-color: $Secondary;
        color: $Main;
        & > p {
            font-weight: bold;
            padding-right: .5rem;
        }

        & > svg {
            animation: rotateY 2s ease-in-out infinite;
        }

        &:hover {
            scale: 1.1;
        }
    } 
}