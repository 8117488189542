@use '../../Styles/Partials/fonts' as *;
@use '../../Styles/Partials/mixins' as *;
@use '../../Styles/Partials/colours' as *;

.contacts {
    &__container {
        min-height: calc(50vh - 9rem);
        padding: 2rem 2rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        background: linear-gradient($Main, black);
        @include desktop {
            padding: 2rem calc((100vw - 64rem) / 2);
        }
    }
    &__loading {
        display: flex;
        justify-content: center;
        padding-top: 4rem;
    }

    &-loaded__container {
        border-radius: 0.5rem;
        border: 2px solid $Secondary;
    }
}

.contact {
    &-title {
        color: $Secondary;
        font-size: 0.83rem;
        padding: 0.25rem;
        text-align: center;
        @include tablet {
            font-size: 1rem;
        }

        &__name {
            width: 25%;
            @include tablet {
                width: 25%;
            }
        }

        &__email {
            width: 55%;
            @include tablet {
                width: 30%;
            }
        }

        &__date {
            display: none;
            width: 30%;
            @include tablet {
                display: block;
                width: 30%;
            }
        }

        &__delete {
            width: 20%;
            @include tablet {
                width: 15%;
            }
        }
        
        &__container {
            display: flex;
            align-items: center;
            width: 100%;
            border-bottom: 1px solid $Secondary;
            height: 2rem;
        }
    }

    &__container {
        display: flex;
        align-items: center;
        width: 100%;
        height: 2rem;
        border-bottom: 1px solid $Secondary;
        
        & > p {
            text-align: center;
            font-size: 0.83rem;
            padding: 0.25rem;
            @include tablet {
            font-size: 1rem;
        }
        }
    }

    &__name {
        width: 25%;
        color: $Secondary;
        @include tablet {
            width: 25%;
        }
    }

    &__email {
        width: 55%;
        color: $Secondary;
        @include tablet {
            width: 30%;
        }
    }

    &__date {
        display: none;
        width: 30%;
        color: $Secondary;
        @include tablet {
            display: block;
            width: 30%;
        }
    }

    &__delete {
        width: 20%;
        text-align: center;
        color: $Secondary;
        position: relative;
        @include tablet {
            width: 15%;
        }

        &-overlay {
            position: absolute;
            width: 1rem;
            height: 1rem;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -60%);
            cursor: pointer;
        }
    }   
}