@use '../../Styles/Partials/fonts' as *;
@use '../../Styles/Partials/mixins' as *;
@use '../../Styles/Partials/colours' as *;

.gallery {
    &__links {
        font-size: .75rem;
        font-weight: 200;
        color: rgba(255, 255, 255, 0.7);
        transition: 0.3s ease-in-out;
        @include tablet {
            font-size: 1rem;
        }
        &:hover {
            color: $Secondary;
            font-weight: 400;
            transform: scale(1.05);
        } 
    }

    &__container {
        height: 4rem;
        width: 100%;
        display: flex;
        gap: 1rem;
        justify-content: center;
        align-items: center;
        background-color: $Main;
        @include tablet {
            gap: 2rem;
        }
    }

    &__loading {
        display: flex;
        justify-content: center;
        padding-top: 4rem;
        width: 100%;
        background: linear-gradient($Main, black);
        height: 80vh;
    }
}