@use '../../Styles/Partials/fonts' as *;
@use '../../Styles/Partials/mixins' as *;
@use '../../Styles/Partials/colours' as *;

.photos {
    &__top-container {
        min-height: calc(50vh - 9rem);
        padding: 2rem 2rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        background: linear-gradient($Main, black);
        @include desktop {
            padding: 2rem calc((100vw - 64rem) / 2);
        }
    }

    &__container {
        display: flex;
        justify-content: space-between;
        padding: 1rem 0.75rem;
        border-bottom: 1px solid $Secondary
    }

    &__loading {
        display: flex;
        justify-content: center;
        padding-top: 4rem;
    }

    &-loaded__container {
        border-radius: 0.5rem;
        border: 2px solid $Secondary;
    }

    &-title, &__tags-title, &__top-tag-title {
        color: $Secondary;
        font-size: 0.83rem;
        padding: 0.25rem;
        text-align: center;
        
        @include tablet {
            font-size: 1rem;
        }
        &__container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0.75rem;
            border-bottom: 1px solid $Secondary;
        }
    }

    &-title__update, &-title__delete {
        width: 25%;
    }

    &-title__photo {
        width: 20%;
    }

    &-title__tags {
        width: 30%;
    }

    &__thumb {
        width: 100%;
        max-height: 10rem;
        object-fit: contain;
        &-container {
            width: 20%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &__delete {
        color: $Secondary;
        position: relative;
        width: 25%;
        height: inherit;
        display: flex;
        align-items: center;
        justify-content: center;

        & > svg {
            height: 1.5rem;
            width: 1.5rem;
            @include tablet {    
                height: 2rem;
                width: 2rem;
            }
        }
        &-overlay {
            position: absolute;
            height: 1.5rem;
            width: 1.5rem;
            @include tablet {    
                height: 2rem;
                width: 2rem;
            }
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            cursor: pointer;
        }
    }

    &__tags, &__top-tag {
        min-width: 4rem;
        max-width: 8rem;

        @include tablet {
            min-width: 6rem;
        }
        &-container {
            width: 30%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center
        }
    }

    &__update {
        @include button;
        padding: 0 0.5rem;
        background-color: $Secondary;
        @include tablet {
            padding: 0 1rem;
        }
        &-container {
            width: 25%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}