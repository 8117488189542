@use '../../Styles/Partials/fonts' as *;
@use '../../Styles/Partials/mixins' as *;
@use '../../Styles/Partials/colours' as *;

.homepage {

    &__hero {
        width: 100%;
        height: calc(100vh - 5rem);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url('../../Assets/Images/IMG_3783.webp');
        position: relative;

        &-overlay {
            background:linear-gradient( rgb(13, 20, 54, 1), rgb(13, 20, 54, .5), transparent, transparent, black);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        &-tagline {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: flex-end;
            padding: 0 2rem 1rem;
            
            @include tablet {
                justify-content: space-between;
            }

            @include desktop {
                padding: 0 calc((100vw - 64rem) / 2) 1rem;
            }
        }

        &-location {
            display: flex;
            color: rgba(255, 255, 255, 0.7);
            z-index: 1;
            align-items: center;
            & > svg {
                animation: spinner 5s ease-in-out infinite;
            }
            & > p {
                padding-left: 1rem;
            }
        }

        &-text {
            font-size: .8rem;
            line-height: 1.5rem;
            font-weight: 200;
            color: rgba(255, 255, 255, 0.5);
        }

        &-tag {
            display: none;
            z-index: 1;
            color: rgba(255, 255, 255, 0.5);
            @include tablet {
                display: flex;
            }
        }
    }

    &__learn-more {
        display: flex;
        justify-content: center;
        padding: 1rem;
        
        &-btn {
            @include button;
            border-radius: 0.5rem;
            background-color: $Secondary;
            color: black;
            transition: 0.3s ease-in-out;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 0.25rem;
            & > svg {
                width: 1rem;
                height: 1rem;
            }
            &:hover {
                scale: 1.1;
            }
        }

    }

    &__about {
        display: flex;
        height: auto;
        width: 100%;
        padding: 5rem 2rem 3rem;
        background-color: black;
        flex-direction: column;
        
        &-title {
            
            text-align: left;
            color: $Secondary;
            font-size: 1.75rem;
            font-style: italic;
            font-weight: 900;
            padding-bottom: 2rem;
            @include tablet {
                font-size: 2.25rem;
            }
            @include desktop {
                font-size: 3rem;
            }

            &-container {
                position: relative;
            }

            &-border {
                position: absolute;
                width: 50%;
                height: 100%;
                top: -1rem;
                left: -1rem;
                border-top: 2px solid $Secondary;
            }
        }

        @include desktop {
            padding: 5rem calc((100vw - 64rem) / 2) 3rem;
        }

        &-copy {
            color: white;
            margin: 1rem 0;
        }
    }

    &__gallery {
        display: flex;
        height: auto;
        width: 100%;
        padding: 5rem 2rem 3rem;
        background-color: black;
        flex-direction: column;
        
        &-title {
            
            text-align: right;
            color: $Secondary;
            font-size: 1.75rem;
            font-style: italic;
            font-weight: 900;
            //text-shadow: 2px 2px 4px white;
            @include tablet {
                font-size: 2.25rem;
            }
            @include desktop {
                font-size: 3rem;
            }

            &-container {
                position: relative;
            }

            &-border {
                position: absolute;
                width: 50%;
                height: 100%;
                bottom: -1rem;
                right: -1rem;
                border-bottom: 2px solid $Secondary;
            }
        }

        @include desktop {
            padding: 5rem calc((100vw - 64rem) / 2) 3rem;
        }
    }
}

.gallery-links {
    &__container {
        display: grid;
        grid-template: auto auto / 1fr 1fr;
        padding: 2rem 0;
        column-gap: 1rem;
        width: 100%;

        @include tablet {
            grid-template: auto / 1fr 1fr 1fr 1fr;
            column-gap: 2rem;
        }
    }

    &__cta {
        display: flex;
        justify-content: center;
        width: 100%;
        margin: 2rem 0 5rem;
        align-items: center;
        cursor: pointer;
        
        & > svg {
            color: white;
            transition: 0.3s ease-in-out;
        } 

        &-text {
            font-size: 1rem;
            color: white;
            transition: 0.3s ease-in-out;
            padding-right: .5rem;
            
            &:hover {
                color: $Secondary;
                font-size: 1.1rem;
            }    
            &:hover + svg {
                color: $Secondary;
                font-size: 1.1rem;
            }
    
        }
    }

    &__card {
        flex-wrap: wrap;
        width: 100%;
        border-radius: .5rem;
        position: relative;
        height: 20rem;
        display: flex;
        justify-content: center;
        cursor: pointer;
        overflow: hidden;

        &:hover > h3 {
            bottom: 3rem;
        }

        &:hover > img {
            filter: grayscale(80%);
            transform: scale(1.06);
        }

        &:hover > div {
            border-right: 1px solid $Secondary;
        }

        @include tablet {
            height: 24rem;
        }


        &-title {
            position: absolute;
            bottom: 2rem;
            font-size: 1rem;
            font-style: italic;
            font-weight: 200;
            color: $Secondary;
            transition: 0.3s ease-in-out;
            z-index: 2;
            @include tablet {
                font-size: 1.5rem;
            }
        }

        &-overlay {
            position: absolute;
            border-radius: .5rem;
            border-bottom: 2px solid $Secondary;
            border-right: 2px solid transparent;
            transition: 0.3s ease-in-out;
            background: linear-gradient(transparent,  black);
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
        }

        &--low {
            margin-top: 1rem;
            @include tablet {
                margin-top: 2rem;                
            }
        }

        &-image {
            border-radius: .5rem;
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: 0.3s ease-in-out;
          }
    }
}

.loader-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background: $Main;
    z-index: 10;
}

.spinner {
    font-size: 5rem;
    padding: 2rem;
    background: linear-gradient(to right, $Secondary, white);
    background-clip: text;
    color: transparent;
    font-family: "Road Rage";
    animation: heartbeat 1.2s linear infinite;
}

#hero1 {
    background-image: url('../../Assets/Images/IMG_3783.webp');
}
#hero2 {
    background-image: url('../../Assets/Images/IMG_3116.webp');
}
#hero3 {
    background-image: url('../../Assets/Images/DSC03412.webp');
}
#hero4 {
    background-image: url('../../Assets/Images/DSC02943.webp');
}