@font-face {
    font-family: 'Road Rage';
    src: url('../../Assets/Fonts/RoadRage.woff2') format('woff2'),
        url('../../Assets/Fonts/RoadRage.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bohemian Typewriter';
    src: url('../../Assets/Fonts/BohemianTypewriter.woff2') format('woff2'),
        url('../../Assets/Fonts/BohemianTypewriter.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,600;1,200;1,600;1,900&display=swap');