@use '../../Styles/Partials/fonts' as *;
@use '../../Styles/Partials/mixins' as *;
@use '../../Styles/Partials/colours' as *;

.masonry-grid {
    display: flex;
    justify-content: center;
    width: auto;
    background: linear-gradient($Main, black);
    padding: 1rem;
    @include desktop {
        padding: 1rem calc((100vw - 64rem) / 2);
    }
}
.masonry-grid_column {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-clip: padding-box;
}
  
.masonry-grid_column a {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    & > img {
        margin-bottom: 30px;
        object-fit: contain;
        width: 90%;
        transition: 1s ease-in-out;
        @include tablet {
            width: 80%;
        }
    }
}

@keyframes fadeUp {
    from {
            opacity: 0;
    } 
    to  {
            opacity: 1;
   } 
}