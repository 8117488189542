@use '../../Styles/Partials/fonts' as *;
@use '../../Styles/Partials/mixins' as *;
@use '../../Styles/Partials/colours' as *;

.store {
    background: linear-gradient($Main, black);
    width: 100%;
    padding: 2rem;

    @include desktop {
        padding: 2rem calc((100vw - 64rem) / 2);
    }

    &__title {
            
        text-align: left;
        color: $Secondary;
        font-size: 1.75rem;
        font-style: italic;
        font-weight: 900;
        padding-bottom: 2rem;
        @include tablet {
            font-size: 2.25rem;
        }
        @include desktop {
            font-size: 3rem;
        }

        &-container {
            position: relative;
            padding-bottom: 2rem;
            height: 10rem;
        }

        &-border {
            position: absolute;
            width: 8.5rem;
            height: 100%;
            top: -1rem;
            left: -1rem;
            border-top: 2px solid $Secondary;

            @include tablet {
                width: 11.25rem;
            }
            @include desktop {
                width: 15rem;
            }
        }
    }

    &__hero {
        background-image: url('../../Assets/Images/DSC03412.webp');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        width: 100%;
        &-container {
            width: 100%;
            height: 60vh;
            position: relative;
        }

        &-overlay {
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: radial-gradient(transparent, rgba(0, 0, 0, 0.8));
        }
    }
}