@use '../../Styles/Partials/fonts' as *;
@use '../../Styles/Partials/mixins' as *;
@use '../../Styles/Partials/colours' as *;

.contact {
    &-container {
        background-color: black;
        display: flex;
        flex-direction: column;
        padding: 0;
    }

    &__modal {

        &-bg {
            position: fixed;
            display: none;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: #202020;
            opacity: 0.55;
            overflow: hidden;
            z-index: 10;
        }

        &-close {
            position: absolute;
            top: 1rem;
            right: 1rem;
            justify-content: right; 
            cursor: pointer;
            & > svg {
                fill: $Main;
            }
        }

        &-container {
            display: none;
            flex-direction: column;
            position: fixed;
            background-color: white;
            width: 90%;
            top: 25%;
            left: 50%;
            transform: translate(-50%, -25%);
            border-radius: 0.5rem;
            padding: 1rem;
            z-index: 11;
            animation: animatetop 0.4s;
            @include desktop {
                width: 50%;
            }
          }

          @keyframes animatetop{from{top:-300px;opacity:0} to{top:25%;opacity:1}}
          
          &-text {
            display: flex;
            color: $Main;
            width: 80%;
            margin: 1rem auto;
            justify-content: center;
            text-align: center;
            line-height: 2rem;
            font-size: 1.1rem;
          }
          
          &-button-container {
            display: flex;
            margin-top: auto;
            justify-content: space-between;
          }
          
          &-confirm-button {
            display: inline-flex;
            background-color: #cc0000;
            color: white;
            padding: 0.7rem 1.4rem;
            border: none;
            border-radius: 0.3rem;
            font-size: 1rem;

            &:hover {
                background-color: red;
                cursor: pointer;
            }
          }
          
          &-cancel-button {
            background-color: #999999;
            &:hover {
                background-color: #b2b2b2;
                cursor: pointer;
            }
          }
          
          
          
          
    }

    &__hero {
        &-container {
            width: 100%;
            height: 50vh;
            background-image: url('../../Assets/Images/IMG_3672.webp');
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            position: relative;

            @include tablet {
                height: 70vh;
            }
        }

        &-overlay {
            background:linear-gradient( rgb(13, 20, 54, 1), rgb(13, 20, 54, .5), transparent, transparent, black);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &__title {
            
        text-align: left;
        color: $Secondary;
        font-size: 1.75rem;
        font-style: italic;
        font-weight: 900;
        width: fit-content;
        padding-bottom: 2rem;
        @include tablet {
            font-size: 2.25rem;
        }
        @include desktop {
            font-size: 3rem;
        }

        &-container {
            margin: 0 2rem;
            position: relative;

            @include desktop {
                margin: 0 calc((100vw - 64rem) / 2);
            }
        }

        &-border {
            position: absolute;
            width: 8.5rem;
            height: 100%;
            top: -1rem;
            left: -1rem;
            border-top: 2px solid $Secondary;

            @include tablet {
                width: 11.25rem;
            }
            @include desktop {
                width: 15rem;
            }
        }
    }

    &-form {
        &__container {
            padding: 2rem 2rem;
            width: 100%;
            display: flex;
            flex-direction: column;
            @include desktop {
                padding: 2rem calc((100vw - 64rem) / 2);
            }
        }

        &__name {
            background-color: $FormBG;
            padding: 0.5rem;
            border-radius: 0.5rem;
            border: 2px solid transparent;
            &-container {
                display: flex;
                flex-direction: column;
                
                @include tablet {
                    flex-direction: row;
                    gap: 1rem;
                }
                &--left {
                    padding-bottom: 1rem;
                }

                &--left, &--right {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    @include tablet {
                        width: 50%;
                        padding-bottom: 0;
                    }
                }
            }
        }

        &__email {
            padding: 0.5rem;
            border-radius: 0.5rem;
            background-color: $FormBG;
            border: 2px solid transparent;
        }

        &__message {
            border-radius: 0.5rem;
            border: 2px solid transparent;
            background-color: $FormBG;
            resize: vertical;
            padding: .5rem;
            height: 8rem;
        }

        &__email:focus-visible, &__name:focus-visible, &__message:focus-visible {
            outline: none;
            border: 2px solid $Secondary;
        }

        &__subtitle {
            padding: 1rem 0 0.25rem;
            color: $Secondary;
            font-size: 1.125rem;
            font-style: italic;
        }

        &__hidden {
            display: none;
        }

        &__error {
            color: red;
            font-size: .75rem;
            line-height: 1.125rem;
            display: flex;
            align-items: center;
            padding-top: .25rem;

            & > svg {
                height: 1rem;
                width: 1rem;
                padding-right: .25rem;
            }

            & > p {
                margin: 0;
            }
        }

        &__submit {
            @include button;
            background-color: $Secondary;
            color: black;
            width: 8rem;
            border-radius: 0.5rem;
            @include tablet {
                width: 8rem;
            }

            &-container {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                padding: 2rem 0;

                @include tablet {
                    justify-content: right;
                }
           } 
        }
    }
}

.error-state {
    border: 2px solid red;
}