@mixin tablet {
    @media only screen and (min-width: 768px) {
        @content;
    }
}

@mixin desktop {
    @media only screen and (min-width: 1280px) {
        @content;
    }
}

@mixin button {
    font-size: 0.8125rem;
    font-weight: 600;
    line-height: 1.25rem;
    text-align: center;
    height: 2.25rem;
    width: 100%;
    padding: 0 1rem;
    border-radius: 0.5rem;
    border-style: none;

    &:hover {
        cursor: pointer;
    }

    @include tablet {
        font-size: 0.875rem;
        line-height: 2.5rem;
        height: 2.5rem;
        width: auto;
    }
}

@keyframes heartbeat
{
  0%
  {
    transform: scale( .85 );
  }
  20%
  {
    transform: scale( 1 );
  }
  40%
  {
    transform: scale( .85 );
  }
  60%
  {
    transform: scale( 1 );
  }
  80%
  {
    transform: scale( .85 );
  }
  100%
  {
    transform: scale( .85 );
  }
}

@keyframes spinner {
    0% {
        transform: rotate(0deg) scale(1);
    }
    20% {
        transform: rotate(0deg) scale(1);
    }
    50% {
        transform: rotate(360deg) scale(1.4);
    }
    80% {
        transform: rotate(720deg) scale(1);
    }
    100% {
        transform: rotate(720deg) scale(1);
    }
}

@keyframes rotateY {
    0% {
        transform: scale(1,1);
    }
    25% {
        transform: scale(1,1);
    }
    50% {
        transform: scale(-1,1);
    }
    75% {
        transform: scale(1,1);
    }
    100% {
        transform: scale(1,1);
    }
}

@keyframes fade {
    0% {
        opacity: 1;
    }
    90% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.loader {
    --c:linear-gradient(#ffba19 0 0);
    background: 
      var(--c),
      var(--c),
      var(--c),
      var(--c),
      var(--c),
      var(--c),
      var(--c),
      var(--c),
      var(--c);
    background-size: 16px 16px;
    background-repeat: no-repeat;
    animation: 
      sh2-1 1s infinite,
      sh2-2 1s infinite;
  }
  
  @keyframes sh2-1 {
    0%,100% {width:45px;height: 45px}
    35%,65% {width:65px;height: 65px}
  }
  
  @keyframes sh2-2 {
    0%,40%  {background-position: 0 0,0 50%, 0 100%,50% 100%,100% 100%,100% 50%,100% 0,50% 0,  50% 50% }
    60%,100%{background-position: 0 50%, 0 100%,50% 100%,100% 100%,100% 50%,100% 0,50% 0,0 0,  50% 50% }
  }