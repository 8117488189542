@use '../../Styles/Partials/fonts' as *;
@use '../../Styles/Partials/mixins' as *;
@use '../../Styles/Partials/colours' as *;

.admin {
    &__links {
        font-size: .75rem;
        font-weight: 200;
        padding: 0.5rem 0;
        color: rgba(255, 255, 255, 0.7);
        transition: 0.3s ease-in-out;
        @include tablet {
            font-size: 1rem;
        }
        &:hover {
            color: $Secondary;
            font-weight: 400;
            transform: scale(1.05);
        } 
    }

    &__selected {
        color: $Secondary;
        border-bottom: 1px solid $Secondary;
    }

    &__container {
        height: 4rem;
        width: 100%;
        display: flex;
        gap: 1rem;
        justify-content: center;
        align-items: center;
        background-color: $Main;
        @include tablet {
            gap: 2rem;
        }
    }

    &__logged-in {
        width: 100%;
        display: flex;
        min-height: calc(50vh - 9rem);
        justify-content: center;
        background: linear-gradient($Main, black);
        & > p {
            color: white;
            font-size: 0.83rem;
            padding-top: 2rem;
        }
    }
}