@use '../../Styles/Partials/fonts' as *;
@use '../../Styles/Partials/mixins' as *;
@use '../../Styles/Partials/colours' as *;

.footer {

    &__container {
        justify-content: flex-end;
        height: 50vh;
        overflow: hidden;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: black;
    }

    &__text {
        color: rgba(255, 255, 255, 0.8);
        text-align: center;
        font-size: .75rem;
        &-container {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 1rem;
            padding-bottom: 1.5rem;
        z-index: 2;

        }
    }

    &__link {
        color: $Secondary;
        
        &:hover {
            animation: heartbeat 2s infinite;
        }

        &-container {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 1.5rem;
            padding: 1rem;
            z-index: 2;
        }
    }

    &__login {
        padding-left: 1rem;
        border-left: 1px solid white;

        &:hover {
            color: white;
        }
    }

    &__logo {
        font-family: "Road Rage";
        font-size: 2rem;
        background: linear-gradient(to right, $Secondary, white);
        background-clip: text;
        color: transparent;
        padding: 1rem;
        &-container {
            z-index: 2;
        }
    }

    &__image {
        background-image: url('../../Assets/Images/footer.webp');
        width: 70vw;
        position: absolute;
        height: 100%;
        right: 0;
        bottom: 0;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    &__top-fade {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 100%;
        background: linear-gradient(black, transparent);
    }
    &__left-fade {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 70vw;
        background: linear-gradient(to right, black, transparent);
    }
}