@use '../../Styles/Partials/fonts' as *;
@use '../../Styles/Partials/mixins' as *;
@use '../../Styles/Partials/colours' as *;

.about {
    &__top-container {
        background: linear-gradient($Main, black);
        min-height: 8rem;
        padding: 2rem;
        @include desktop {
            padding: 2rem calc((100vw - 64rem) / 2);
        }
    }

    &__title {
            
        text-align: left;
        color: $Secondary;
        font-size: 1.75rem;
        font-style: italic;
        font-weight: 900;
        width: fit-content;
        padding-bottom: 2rem;
        @include tablet {
            font-size: 2.25rem;
        }
        @include desktop {
            font-size: 3rem;
        }

        &-container {
            position: relative;
        }

        &-border {
            position: absolute;
            width: 8.5rem;
            height: 100%;
            top: -1rem;
            left: -1rem;
            border-top: 2px solid $Secondary;

            @include tablet {
                width: 11.25rem;
            }
            @include desktop {
                width: 15rem;
            }
        }
    }

    &-me {
        &__container {
            min-height: 50vh;
            padding: 2rem;
            position: relative;
            background-color: black;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            @include tablet {
                justify-content: right;
            }
        }

        &__background {
            position: absolute;
            width: 100%;
            height: 100vh;
            top: 0;
            left: 0;
            background-image: url('../../Assets/Images/DSC02943.webp');
            background-position: center;
            background-size: cover;
            background-attachment: fixed;
            background-repeat: no-repeat;
            @include tablet {
                width: 70%;
            }
        }

        &__overlay {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: linear-gradient(to top, rgba(0, 0, 0, 0.4), black);
            @include tablet {
                background: linear-gradient(black, transparent);
            }
        }

        &__overlay-wide {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.3);
            @include tablet {
                width: 70%;
                background: linear-gradient(to right, transparent, black);
            }
        }
        &__text {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: left;
            justify-content: left;
            z-index: 5;
            position: relative;
            padding: 0 5%;
            @include tablet {
                width: 40%;
            }
        }
        &__subtitle {
            text-align: center;
            font-size: 1rem;
            font-weight: 400;
            color: $Secondary;
            padding-bottom: 1rem;
            @include tablet {
                font-size: 1.25rem;
            }
        }
        &__copy {
            text-align: left;
            font-size: 0.83rem;
            font-weight: 200;
            padding-bottom: 0.66rem;
            color: white;
            @include tablet {
                font-size: 1rem;
            }
        }
    }

    &-subject {
        &__container {
            min-height: 50vh;
            padding: 2rem;
            position: relative;
            background-color: black;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            @include tablet {
                justify-content: right;
            }
        }

        &__background {
            position: absolute;
            width: 100%;
            height: 100vh;
            top: 0;
            left: 0;
            background-image: url('../../Assets/Images/DSC02943.webp');
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            @include tablet {
                width: 70%;
            }
        }

        &__overlay {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.7);
            @include tablet {
                background: rgba(0, 0, 0, 0.1);
            }
        }

        &__overlay-wide {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.3);
            @include tablet {
                width: 70%;
                background: linear-gradient(to right, transparent, black);
            }
        }
        &__text {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            z-index: 5;
            position: relative;
            border-bottom: 1px solid $Secondary;
            border-right: 1px solid $Secondary;
            padding: 2rem;
            @include tablet {
                width: 40%;
                border-bottom: 2px solid $Secondary;
                border-right: 2px solid $Secondary;   
            }
        }

        &__copy {
            text-align: left;
            font-size: 0.83rem;
            font-weight: 200;
            padding-bottom: 0.75rem;
            color: white;
            @include tablet {
                font-size: 1rem;
            }
        }
    }
}

#about-text-left {
    @include tablet {
        justify-content: left;    

        div:first-child, div:nth-child(3)  {
            left: auto;
            right: 0;
        }

        div:nth-child(3) {
            background: linear-gradient(to left, transparent, black);
        }
    }
}

#fitness-image {
    background-image: url('../../Assets/Images/IMGL8580.webp');
}

#food-image {
    background-image: url('../../Assets/Images/IMGL2996.webp');
}
#pet-image {
    background-image: url('../../Assets/Images/IMG_6095.webp');
    
}
#lifestyle-image {
    background-image: url('../../Assets/Images/IMGL2501.webp');
}