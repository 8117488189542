@use '../../Styles/Partials/fonts' as *;
@use '../../Styles/Partials/mixins' as *;
@use '../../Styles/Partials/colours' as *;

.sidebar {

    &__container {
        position: fixed;
        z-index: 10;
        width: 100%;
        height: 100%;
        background: linear-gradient(black, $Main);
        align-items: center;
        display: flex;
        left: 0;
        transition: 0.5s ease-in-out;
        top: -100%;
        flex-direction: column;
    }

    &__close > svg {
        position: absolute;
        height: 2rem;
        width: 2rem;
        color: white;
        top: 1.25rem;
        right: 1.25rem;
        background: transparent;
        cursor: pointer;
    }

    &__menu {
        padding-top: 10rem;
        display: flex;
        flex-direction: column;
        background: linear-gradient(to top, $Secondary, white);
        background-clip: text;
        text-align: center;
    }

    &__link {
        margin: 1rem 0;
        font-size: 1.5rem;
        color: transparent;
        transition: 0.3s ease-in-out;

        &:hover {
            font-size: 1.7rem
        }
    }

    &__button {
        @include button;
        margin-top: 5rem;
        font-size: 1.8rem;
        height: 4rem;
        line-height: 4rem;
        padding: 0 2rem;
        background-color: $Secondary;
        color: $Main;
        font-weight: bold;
        transition: 0.3s ease-in-out;
        display: flex;
        align-items: center;
        & > p {
            font-weight: bold;
            padding-right: 1rem;
        }

        & > svg {
            animation: rotateY 2s ease-in-out infinite;
        }

        &:hover {
            scale: 1.05;
        }
    }
}
