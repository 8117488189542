@use '../../Styles/Partials/fonts' as *;
@use '../../Styles/Partials/mixins' as *;
@use '../../Styles/Partials/colours' as *;

.login-form {
    &__container {
        min-height: calc(50vh - 5rem);
        padding: 2rem 2rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        background: linear-gradient($Main, black);
        @include desktop {
            padding: 2rem calc((100vw - 64rem) / 2);
        }
    }

    &__username, &__password {
        padding: 0.5rem;
        width: 100%;
        border-radius: 0.5rem;
        background-color: $FormBG;
        border: 2px solid transparent;
        @include tablet {
            width: 14rem;
        }
    }

    &__subtitle {
        padding: 1rem 0 0.25rem;
        color: $Secondary;
        font-size: 1.125rem;
        font-style: italic;
    }

    &__hidden {
        display: none;
    }

    &__error {
        color: red;
        font-size: .75rem;
        line-height: 1.125rem;
        display: flex;
        align-items: center;
        padding-top: .25rem;

        & > svg {
            height: 1rem;
            width: 1rem;
            padding-right: .25rem;
        }

        & > p {
            margin: 0;
        }
    }

    &__submit {
        @include button;
        background-color: $Secondary;
        color: $Main;
        width: 8rem;
        border-radius: 0.5rem;
        @include tablet {
            width: 8rem;
        }

        &-container {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            padding: 2rem 0;

            @include tablet {
                justify-content: left;
            }
       } 
    }
}

.error-state {
    border: 2px solid red;
}
